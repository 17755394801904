<template>
   <div>
      <!-- <ul v-for="(x,index) in data" :key="index">
        <li>{{x.x}}
           <div class="hang">
              {{x.y}}
           </div>
        </li> -->
  <el-table :data="gameareatable" v-loading="cardBuyConsumeDataLoading" v-if="gameareatable.length> 0">
    <el-table-column align="center" v-for="(item,index) in activePlayerDataPropLabelArray" :prop="item.prop" :label="item.label"
      :key="item.prop">
      <template slot-scope="scope">
      {{scope.row[item.prop]?scope.row[item.prop]:'暂无数据'}}
      </template>
    </el-table-column>
 </el-table>

      </ul>
   </div>
</template>

<script>
export default {
  data(){
    return {
  activePlayerDataPropLabelArray:[
    {
    label:'产品',
    prop:'date'
   },{
    label:"上地店烤鱼",
    prop:"15"
   },{
    label:'麻辣店',
    prop:'14'
   },
   {
    label:'火锅店',
    prop:'15'
   },
   {
    label:'油炸点店',
    prop:'15'
   }
   ],
   gameareatable:[{
    date:"宫保鸡丁",
    15:'1',
    14:''
   },{
    date:"香辣牛排",
    15:'0',
    14:''
   },{
    date:"炒栗子",
    15:'1',
    14:'',
   }],
      data:[
        {
          x:'1',
          y:'1',
          z:'8'
        },
         {
          x:'2',
          y:'2',
          z:'8'
        },
         {
          x:'3',
          y:'3',
          z:'8'
        },
         {
          x:'4',
          y:'4',
          z:'8'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  div{
    font-size: 16px;
    ul{
      width: 300px;
      height: 300px;
      border: 1px solid red;
    }
    // .hang{
    //   display: flex;
    //   border: 1px solid red;
    // }
    li{
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      border: 1px solid green;
    }
  }
</style>
